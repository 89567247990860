import Vue from "vue";
import Router from "vue-router";
import store from "./store";

import {auth} from '@/firebase';

function loadView(src, view) {
  return () => import(/* webpackChunkName: "view-[request]" */ `@/${src}/${view}.vue`)
}

Vue.use(Router);

let router = new Router({
  mode: 'history',
  routes: [
    {
      path: '*',
      redirect: '/login'
    },
    {
      path: '/',
      name: "home",
      component: loadView('views','Home')
    },
    {
      path: '/about',
      name: "about",
      component: loadView('views','About')
    },
    {
      path: '/help',
      name: "help",
      component: loadView('views','Help')
    },
    {
      path: '/login',
      name: 'Login',
      component: loadView('views','Login')
    },
    {
      path: '/sign-up',
      name: 'SignUp',
      component: loadView('views','SignUp')
    },
    {
      path: "/profile",
      name: "profile",
      component: loadView('views','Profile'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/watch/:videoId",
      name: "watch",
      component: loadView('views','Watch')
      // meta: {
      //   requiresAuth: true
      // }
    },
    {
      path: "/create/",
      name: "build",
      component: loadView('views','Build'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/create/:videoId",
      name: "create",
      component: loadView('views','Create'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/my-youtorials/",
      name: "myYoutorials",
      component: loadView('views','MyYoutorials'),
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/user/:userId",
      name: "userPage",
      component: loadView('views','User'),
    },
    // {
    //   path: "/about",
    //   name: "about",
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () =>
    //     import(/* webpackChunkName: "about" */ "./views/About.vue")
    // }
  ]
});

router.beforeEach((to, from, next) => {
  // see if User exists or has permission
  let currentUser = auth.currentUser;
  // does this path require auth?
  let requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  // if there is a user, then save it locally, keep checking cause they could get removed anytime.
  console.log('requires auth?', requiresAuth);
  console.log('currentUser', currentUser, !currentUser);
  store.dispatch('checkUserStatus', currentUser).then((results)=>{
    console.log('currentUser', results, currentUser, !currentUser);
  }).catch((err)=>{
    // console.log('error', err);
  });
  // console.log('to path:', to.path);
  if (requiresAuth && !currentUser){
    // console.log('1');
    next('/login')
  }
  else if ((to.path == '/login' || to.path == '/sign-up') && currentUser){
    // console.log('2');
    next('/')
  }
  else if (!requiresAuth && currentUser){
    // console.log('3');
    next()
  }
  else {
    // console.log('4', from.path, to.path);
    next()
  }

});

export default router
