import Vue from "vue";
import Vuex from "vuex";
import firebase  from "firebase/app";
import { db, auth, cloud } from '@/firebase';


Vue.use(Vuex);
Vue.config.devtools = true;

const state = {
  user: {},
  userId: '',
  currentVideo: {},
  buildVideo: {},
  drawer: false,
};

const actions = {
  login({ commit, state }, {kind, email, password }) {
    return new Promise((resolve, reject) => {
      auth.signInWithEmailAndPassword(email, password)
        .then((response) => {
          process.env.NODE_ENV === "development" ? console.log( 'response from state', response ) : null;
          commit('SET_USER_ID', response.user.uid);
          db.collection('users').doc(response.user.uid).get().then((result)=>{
            commit('SET_USER', result.data());
            resolve(true);
          })
        })
        .catch((err) => {
          console.log('error', err);
          reject(err);
        });
    });
  },
  loginSocial({ commit, state }, {kind, email, password}) {
    return new Promise((resolve, reject) => {
      var provider = new firebase.auth.GoogleAuthProvider();
      auth.signInWithPopup(provider).then(function(response) {
        // This gives you a Google Access Token. You can use it to access the Google API.
        var token = response.credential.accessToken;
        // The signed-in user info.
        var user = response.user.uid;
        commit('SET_USER_ID', user);
        process.env.NODE_ENV === "development" ? console.log( user) : null;
          db.collection('users').doc(user).get().then((result)=>{
            commit('SET_USER', result.data());
            resolve(true);
          })

        // ...
      }).catch(function(error) {
        console.log('error', error);
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        // ...
        reject(error);
      });
    });
  },
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      auth.signOut()
        .then(() => {
          commit('LOGOUT');
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  checkUserStatus({ commit, state }) {
    return new Promise((resolve, reject) => {
      auth.onAuthStateChanged((user) => {
        if (user) {
          process.env.NODE_ENV === "development" ? console.log( 'user found', user.uid ) : null;
          commit('SET_USER_ID', user);
          db.collection('users').doc(user.uid).onSnapshot((result)=>{
            process.env.NODE_ENV === "development" ? console.log( 'results', Object.hasOwnProperty.call(result.data(), 'account'), result.data() ) : null;
            if(Object.hasOwnProperty.call(result.data(), 'account')) {
              commit('SET_USER', result.data());
              resolve(result.data());
            }
            else{
              reject('not yet')
            }
          })
        } else {
          reject('User is not logged in.');
        }
      });
    });
  },
  saveUser({ commit, state }, {user}) {
    state.user = user;
  },
  updateUser({commit, state}, data) {
    process.env.NODE_ENV === "development" ? console.log( 'update data',data ) : null;
    return new Promise((resolve, reject) => {
      const dbRef = db.collection('users').doc(state.userId);
      dbRef.update(data).then(function(result) {
        process.env.NODE_ENV === "development" ? console.log( 'result ', result ) : null;
          resolve('ok')
        // ...
      }).catch(function(error) {
        // Handle Errors here.
        reject(error);
      });
    });
  },
    //action to open or close the nav
  toggleDrawer({commit}, val){
    // process.env.NODE_ENV === "development" ? console.log('store drawer', val) : null
    store.commit('TOGGLE_DRAWER', val)
  },
  getVideo({commit, state}, {videoId, kind}) {
    return new Promise((resolve, reject) => {
      const dbRef = db.collection('videos').doc(videoId);
      dbRef.get().then(function(result) {
          // console.log('getVideo', result.data());
          let newData = result.data();
          newData['.key'] = videoId;
          if(kind == 'watch'){
            commit('SET_CURRENT_VIDEO', newData);
          }
          else{
            commit('SET_BUILD_VIDEO', newData);
          }
          resolve(newData)

        // ...
      }).catch(function(error) {
        // Handle Errors here.
        reject(error);
      });
    });
  },
  setVideo({commit, state}, {video}) {
    return new Promise((resolve) => {
        commit('SET_CURRENT_VIDEO', video);
        resolve()
        // ...
      })
  },
  setBuildVideo({commit, state}, {video}) {
    // console.log('video', video);
    return new Promise((resolve) => {
        commit('SET_BUILD_VIDEO', video);
        resolve()
        // ...
      })
  },
  saveVideo({commit, state}) {
    process.env.NODE_ENV === "development" ? console.log( 'save video', state.buildVideo['.key'], state.buildVideo ) : null;
    return new Promise((resolve, reject) => {
      // let newArray = state.buildVideo.slice();
      let newArray = JSON.parse(JSON.stringify(state.buildVideo))
      delete newArray[".key"];
      const dbRef = db.collection('videos').doc(state.buildVideo['.key']);
      dbRef.update(newArray).then(function(result) {
          // let newData = result.data();
          resolve('ok')
        // ...
      }).catch(function(error) {
        // Handle Errors here.
        reject(error);
      });
    });
  },
  deleteVideo({commit, state}) {
    return new Promise((resolve, reject) => {
      process.env.NODE_ENV === "development" ? console.log( "state.buildVideo['.key']",state.buildVideo['.key'] ) : null;
      db.collection('videos').doc(state.buildVideo['.key']).delete().then((result)=>{
          commit('DELETE_BUILD_VIDEO', false);
          resolve();
        })
        // .catch((x)=>{
        //   reject('Some error happened', x);
        // })
      })
  },
  // updatePlays({commit, state}, {videoId, data}) {
  //   return new Promise((resolve, reject) => {
  //     // const dbRef = db.collection('events').doc(videoId);
  //     const dbRef = cloud.httpsCallable('updatePlays')
  //     updatePlays({data: data}).then(function(result) {
  //     // dbRef.set({'plays': data}).then(function(result) {
  //         process.env.NODE_ENV === "development" ? console.log( 'addEvent' ) : null;
  //         resolve(result.data())
  //     }).catch(function(error) {
  //       // Handle Errors here.
  //       reject(error);
  //     });
  //   });
  // },
};

const mutations = {
  LOGOUT(state, user) {
    state.user = null;
    state.userId = null;
  },
  SET_USER(state, user) {
    state.user = user;
  },
  SET_USER_ID(state, user) {
    state.userId = user.uid;
  },
  TOGGLE_DRAWER(state, value){
    state.drawer = value;
  },
  SET_CURRENT_VIDEO(state, video) {
    state.currentVideo = video;
  },
  SET_BUILD_VIDEO(state, video) {
    state.buildVideo = video;
  },
  DELETE_BUILD_VIDEO(state, video) {
    state.buildVideo = {};
  },
};

const getters = {
  GET_USER: state => state.user,
  GET_USER_ID: state => state.userId,
  GET_DRAWER: state => state.drawer,
  GET_CURRENT_VIDEO: state => state.currentVideo,
  GET_BUILD_VIDEO: state => state.buildVideo,

}


const store = new Vuex.Store({
  state,
  mutations,
  actions,
  getters
})

export default store
