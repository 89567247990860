// import '@babel/polyfill'
import Vue from "vue";
// import Vuetify from "vuetify/lib"
import vuetify from './plugins/vuetify';
import colors from 'vuetify/es5/util/colors'

import App from "./App.vue";
import router from "./router";
import store from "./store";
import Plugins from './plugins/plugins'


import ArtyomPlugin from './plugins/artyom.js';

import VueScrollTo from 'vue-scrollto'


import moment from "moment"
import VueMoment from "vue-moment"

import VueTrumbowyg from 'vue-trumbowyg';
import 'trumbowyg/dist/ui/trumbowyg.css';

// import VueAnalytics from 'vue-analytics'
import VueMeta from 'vue-meta'

import getVideoId from '@/plugins/youtube-id.js';

import VueTour from 'vue-tour'

require('vue-tour/dist/vue-tour.css');


import {auth, analytics, db} from '@/firebase';
// import * as firebase from 'firebase/app'
// import {firebase} from 'firebase/app'
// import "@firebase/firestore";
// import "@firebase/auth";
// Vue.prototype.$firebase = firebase;
import VueFirestore from 'vue-firestore';

// Vue.use(VueVideoPlayer, /* {
//   options: global default options,
//   events: global videojs events
// } */)
Vue.use(Plugins);
Vue.use(VueScrollTo);
Vue.use(VueFirestore);
Vue.use(VueMoment, {moment});
Vue.use(VueTrumbowyg);
Vue.use(ArtyomPlugin);
Vue.use(getVideoId);
Vue.use(VueMeta);
Vue.use(VueTour);


const isProd = process.env.NODE_ENV === 'production';
// Vue.use(VueAnalytics, {
//   id: '183693798',
//   router,
//   vuetify,
//   debug:{
//     enabled: !isProd,
//     sendHitTask: isProd
//   }
// })


Vue.config.productionTip = false;

Vue.config.ignoredElements = [
  'a-avataaar'
]

// save a reference to the firestore database
// to access it in the future
let app;
auth.onAuthStateChanged(function(user) {
  if (user) {
    if(process.env.NODE_ENV === "development"){
      console.log('no analytics auth for user', user.uid);
    }
    else{
      analytics.setUserId(user.uid);
      analytics.setUserProperties({
        account_type: "Basic" // can help you to define audiences
      });
    }

  }
  if(!app){
    /* eslint-disable no-new */
    app = new Vue ({
      el:'#app',
      // template: '<App/>',
      // components: {App},
      router,
      store,
      vuetify,
      render: h => h(App)
    })
  }
});
