<template>
  <div id="innerApp">
    <v-app >
      <drawer/>
      <router-view/>
    </v-app>
  </div>
</template>

<script>

import {analytics} from '@/firebase'
import { version } from "../package.json";
const drawer = () => import( "@/components/shared/drawer");
export default {
  name: 'app',
  components: {drawer},
  data(){
    return{

    }
  },
  methods:{

  },
  metaInfo: {
    changed(metaInfo) {
      if(process.env.NODE_ENV === "development"){
        // console.log('auth not started')
      }
      else{
        analytics.setCurrentScreen(metaInfo.title);
        analytics.logEvent("page_view");
        analytics.logEvent("screen_view", {
          app_name: window && window.matchMedia("(display-mode: standalone)").matches ? "pwa" : "web",
          screen_name: metaInfo.title,
          app_version: version
        });
      }
    }
  },
    // mounted() {
    //   firebase.auth().onAuthStateChanged(user => {
    //     if (user) {
    //       this.$analytics.setUserId(user.uid);
    //       this.$analytics.setUserProperties({
    //         account_type: "Basic" // can help you to define audiences
    //       });
    //     }
    //   });
    // }
}
</script>

<style lang="scss">
body{
  height: 100%;
}
#innerApp{
  height: 100%;
}
#app {
  height: 100%;
  // font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  .v-application--wrap{
    // padding-top: 64px;
    height: 100vh;
  }
  button.gradient, .gradientBG{
    background: #FEAC5E!important;  /* fallback for old browsers */
    background: -webkit-linear-gradient(to right, #4BC0C8, #C779D0, #FEAC5E)!important;  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #4BC0C8, #C779D0, #FEAC5E)!important; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  }
}
// #nav {
//   padding: 0px;
//   a {
//     font-weight: bold;
//     color: #2c3e50;
//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }

@media (min-width: 960px){

  .container {
    max-width: 98%!important;
  }
}

@keyframes rotating
	{
	from
		{
		transform: rotate(0deg);
		-o-transform: rotate(0deg);
		-ms-transform: rotate(0deg);
		-moz-transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		}
	to
		{
		transform: rotate(360deg);
		-o-transform: rotate(360deg);
		-ms-transform: rotate(360deg);
		-moz-transform: rotate(360deg);
		-webkit-transform: rotate(360deg);
		}
	}
@-webkit-keyframes rotating
	{
	from
		{
		transform: rotate(0deg);
		-webkit-transform: rotate(0deg);
		}
	to
		{
		transform: rotate(360deg);
		-webkit-transform: rotate(360deg);
		}
	}
.rotating
	{
	-webkit-animation: rotating 1s linear infinite;
	-moz-animation: rotating 1s linear infinite;
	-ms-animation: rotating 1s linear infinite;
	-o-animation: rotating 1s linear infinite;
	animation: rotating 1s linear infinite;
	}

  .bounce {
    animation: bounce .5s .5s;
    transform: scale(0.85);
  }

  @keyframes bounce {
    0% { transform: scale(1.1); opacity: 1 }
    50% { transform: scale(1.6); opacity: .7; }
    60% { transform: scale(0.6); opacity: 1 }
    80% { transform: scale(0.95) }
    100% { transform: scale(0.85) }
  }


  .hide{
    display: none!important;
  }

  .v-tour{
    .v-step{
      background: var(--v-primary-base)!important;
    }
    .v-step__header{
      background: var(--v-primary-darken2)!important;
    }
  }

</style>
