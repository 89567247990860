import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import 'vuetify/dist/vuetify.min.css'

Vue.use(Vuetify)
export default new Vuetify({
  theme:{
    themes: {
      light:{
        // primary: '#D50000',
        primary: '#53BBC9',
        secondary: '#D082BD',
        accent: '#FCAA62',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107'
      },
    },
    options: {
      customProperties: true
    },
  },
  icons:{
    iconfont: 'md'
  }
})
