import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
import "firebase/auth";
import "firebase/storage";
import "firebase/analytics";

const firebaseApp = firebase.initializeApp({
  apiKey: "AIzaSyBAFa47wUXB_1k9lvLdy8xmsYESNs4ghXk",
  authDomain: "watchyoutorials.com",
  databaseURL: "https://learnt-b18b1.firebaseio.com",
  projectId: "learnt-b18b1",
  storageBucket: "learnt-b18b1.appspot.com",
  messagingSenderId: "7073656297",
  appId: "1:7073656297:web:3963ffd43dccc25162ef78",
  measurementId: "G-22XC868NFL"
});

export const db = firebase.firestore();
export const firestorage = firebase.storage();
export const auth = firebase.auth();
export const cloud = firebase.functions();
export const analytics = firebase.analytics();
